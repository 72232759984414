.image-uploader-container {
    width: 100%;
    margin-bottom: 16px;
  }
  
  .uploaded-images-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .upload-list-item {
    width: 100%;
    padding: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }
  
  .image-preview {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  
  .image-metadata {
    width: 100%;
  }